import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Loading } from './components/Loading';
import ScrollToTop from './components/routing/ScrollToTop';

const Home = lazy(() => import('./components/pages/Home'));
const AddCoin = lazy(() => import('./components/pages/AddCoin'));
const NavBar = lazy(() => import('./components/pages/NavBar'));
const Footer = lazy(() => import('./components/pages/Footer'));
const ViewCoin = lazy(() => import('./components/pages/ViewCoin'));
const Advertise = lazy(() => import('./components/pages/advertise/Advertise'));
const TopCoin = lazy(() => import('./components/pages/TopCoin'));
const Error404 = lazy(() => import('./components/pages/error/Error404'));
const Error500 = lazy(() => import('./components/pages/error/Error500'));
const Cache = lazy(() => import('./components/pages/Cache'));
const Login = lazy(() => import('./components/pages/Login'));
const Privacy = lazy(() => import('./components/pages/Privacy'));
const Terms = lazy(() => import('./components/pages/Terms'));
const Analytics = lazy(() => import('./components/Analytics'));
const Sitemap = lazy(() => import('./components/routing/Sitemap'));
const Wallet = lazy(() => import('./components/pages/wallet/Wallet'));
const Scanner = lazy(() => import('./components/pages/Scanner'));

const App: React.FC = () => {
  return (
    <main className='App '>
      <BrowserRouter>
        <Suspense fallback={Loading({})}>
          <Analytics />
          <NavBar />
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/addcoin' element={<AddCoin />} />
            <Route path='/coin/:id' element={<ViewCoin />} />
            <Route path='/advertise' element={<Advertise />} />
            <Route path='/top' element={<TopCoin />} />
            <Route path='/wallet' element={<Wallet />} />
            <Route path='/scanner' element={<Scanner />} />
            <Route path='/login' element={<Login />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/sitemap' element={<Sitemap />} />
            <Route path='/500' element={<Error500 />} />
            <Route path='/cache' element={<Cache />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </main>
  )
}

export default App