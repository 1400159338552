import { Transaction } from "@depay/widgets"
import axios, { AxiosResponse } from "axios"

export const baseUrl: string = process.env.NODE_ENV == 'development' ? 'http://localhost:4000' : "https://api.cryptogems.tips"

const apiResponse = async <T>(api: () => Promise<AxiosResponse<ResponseApi<T>>>): Promise<ResponseApi<T>> => {
	try {
		let response = await api()

		if (response.status != 200) {
			throw new Error(response.status.toString())
		}

		return response.data
	} catch (error: any) {
		if (error && error.response && error.response.status == 429) {
			return { ok: false, message: 'Too many request' }
		}

		return { ok: false, message: 'Internal Error' }
	}
}


export const searchCoins = async (query: string): Promise<ResponseApi<SearchCoins>> => {
	return await apiResponse<GetCoins>(() => axios.get(
		baseUrl + "/search/" + query
	))
}

export const getCoins = async (): Promise<ResponseApi<GetCoins>> => {
	return await apiResponse<GetCoins>(() => axios.get(
		baseUrl + "/coins"
	))
}

export const getCoin = async (id: string): Promise<ResponseApi<GetCoin>> => {
	return await apiResponse<GetCoin>(() => axios.get(
		baseUrl + "/coin/" + id
	))
}

export const scannerCoin = async (address: string, blockchain: string): Promise<ResponseApi<GetCoin>> => {
	return await apiResponse<GetCoin>(() => axios.get(
		baseUrl + `/scanner?address=${address}&blockchain=${blockchain}`,
		{ withCredentials: true }
	))
}

export const getTopCoins = async (): Promise<ResponseApi<GetTopCoins>> => {
	return await apiResponse<GetTopCoins>(() => axios.get(
		baseUrl + "/top-coins/"
	))
}

export const getSingleTopCoin = async (id: string): Promise<ResponseApi<GetSingleTopCoin>> => {
	return await apiResponse<GetSingleTopCoin>(() => axios.get(
		baseUrl + "/top-coins/" + id
	))
}

export const getBanner = async (): Promise<ResponseApi<IBanner[]>> => {
	return await apiResponse<IBanner[]>(() => axios.get(
		baseUrl + "/banner"
	))
}

export const getPromoted = async (): Promise<ResponseApi<ICoin[]>> => {
	return await apiResponse<ICoin[]>(() => axios.get(
		baseUrl + "/promoted-coins"
	))
}

export const addCoin = async (
	formData: ICoin,
	token: string
): Promise<ResponseApi<AddCoin>> => {

	return await apiResponse<AddCoin>(() => {
		let coin = new FormData()
		coin.append("data", JSON.stringify(formData))
		coin.append("token", token)

		return axios.post(
			baseUrl + "/add-coin",
			coin,
			{ headers: { "Content-type": "multipart/form-data" } })
	})
}


export const addAdv = async (
	formData: IAdv,
	token: string
): Promise<ResponseApi<ApiID>> => {
	return await apiResponse<ApiID>(() => {
		let adv = new FormData()
		adv.append("data", JSON.stringify(formData))
		adv.append('token', token)

		return axios.post(
			baseUrl + "/add-adv",
			adv,
			{ headers: { "Content-type": "multipart/form-data" } }
		)
	})
}

export const addTransaction = async (
	data: Transaction,
	advId: string,
	token: string
): Promise<ResponseApi<void>> => {
	return await apiResponse<void>(() => axios.post(
		baseUrl + "/add-transaction",
		{ advId, token, ...data }
	))
}


export const confirmedTransaction = async (
	data: Transaction,
	advId: string,
	token: string
): Promise<ResponseApi<void>> => {
	return await apiResponse<void>(() => axios.post(
		baseUrl + "/completed-transaction",
		{ advId, token, ...data }
	))
}

export const updateVote = async (
	id: string,
	token: string
): Promise<ResponseApi<ICoin>> => {
	return await apiResponse<ICoin>(() => axios.post(
		`${baseUrl}/update-vote`,
		{ id, token },
		{ withCredentials: true }
	))
}

export const getPrices = async (
): Promise<ResponseApi<IPrice>> => {
	return await apiResponse<IPrice>(() => axios.get(
		`${baseUrl}/adv-prices`
	))
}

export const currentUser = async (
) => {
	try {
		return await axios.get(
			`${baseUrl}/api/current_user`,
			{ withCredentials: true }
		)
	} catch (error: any) {
		throw new Error(error)
	}
}

export const getWallet = async (walletAddress: string, blockchain: string): Promise<ResponseApi<Wallet>> => {
	return await apiResponse<Wallet>(() => axios.get(
		baseUrl + "/getWallet", {
		params: {
			walletAddress,
			blockchain
		},
		withCredentials: true
	}))
}

export const getWalletHistory = async (walletAddress: string, blockchain: string): Promise<ResponseApi<HistoryData>> => {
	return await apiResponse<HistoryData>(() => axios.get(
		baseUrl + "/getWalletHistory"
		, {
			params: {
				walletAddress,
				blockchain
			}
		}))
}




export const getTokens = async (blockchain: string, walletAddress: string): Promise<ResponseApi<Token[]>> => {
	return await apiResponse<Token[]>(() => axios.get(
		baseUrl + "/getTokens"
		, {
			params: {
				blockchain,
				walletAddress
			}
		}))
}

export const hideToken = async (
	address: string,
	blockchain: string,
	tokenAddress: string
): Promise<ResponseApi<Wallet>> => {
	return await apiResponse<Wallet>(() => axios.post(
		baseUrl + "/hideToken",
		{ address, blockchain, tokenAddress }
	))
}

export const showToken = async (
	address: string,
	blockchain: string,
	tokenAddress: string
): Promise<ResponseApi<Wallet>> => {
	return await apiResponse<Wallet>(() => axios.post(
		baseUrl + "/showToken",
		{ address, blockchain, tokenAddress }
	))
}

export const showAllToken = async (
	address: string,
	blockchain: string,
): Promise<ResponseApi<Wallet>> => {
	return await apiResponse<Wallet>(() => axios.post(
		baseUrl + "/showAllToken",
		{ address, blockchain }
	))
}

export async function buyClick(blockchain: BlockchainString, address: string) {
	return await apiResponse<void>(() => axios.post(
		baseUrl + "/buyClick",
		{ blockchain, address },
		{ withCredentials: true }
	))
}
