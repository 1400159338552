export const Loading: React.FC = () => {

    const halvan = {
        animation: 'circleDrop 10s infinite linear',
        strokeDasharray: '180 800',
        fill: 'none',
        stroke: 'url(#gradient)',
        strokeWidth: '23',
        StrokeLinecap: 'round'
    }

    const strec = {
        animation: 'circleDrop 3s infinite linear',
        strokeDasharray: '26 54',
        fill: 'none',
        stroke: 'url(#gradient)',
        strokeWidth: '23',
        StrokeLinecap: 'round'
    }

    const aniation = `@keyframes circleDrop {
      0% {
        stroke-dashoffset: 0;
      }
      100% {
          stroke-dashoffset: -403px;
      }
    }`

    return <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '60vh' }}>
        <style>{aniation}</style>
        <svg style={{ width: 0 }}>
            <defs>
                <filter id="gegga">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="7" result="blur" />
                    <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 20 -10"
                        result="inreGegga"
                    />
                    <feComposite in="SourceGraphic" in2="inreGegga" operator="atop" />
                </filter>
            </defs>
        </svg>
        <svg style={{ filter: 'url(#gegga)' }} width="200" height="200" viewBox="0 0 200 200">
            <defs>
                <linearGradient id="gradientCol">
                    <stop style={{ stopColor: 'var(--logoBlue)' }} offset="0" />
                    <stop style={{ stopColor: 'var(--logoGreen)' }} offset="1" />
                </linearGradient>
                <linearGradient
                    y2="160"
                    x2="160"
                    y1="40"
                    x1="40"
                    gradientUnits="userSpaceOnUse"
                    id="gradient"
                    xlinkHref="#gradientCol"
                />
            </defs>
            <path
                style={halvan}
                d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776 -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64 0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736 -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64 64,-64"
            />
            <circle style={strec} cx="100" cy="100" r="64" />
        </svg>
        <svg style={{ filter: 'blur(5px)', opacity: '0.3', position: 'absolute', transform: 'translate(3px, 3px)' }} width="200" height="200" viewBox="0 0 200 200">
            <path
                style={halvan}
                d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776 -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64 0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736 -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64 64,-64"
            />
            <circle style={strec} cx="100" cy="100" r="64" />
        </svg>
    </div>
}