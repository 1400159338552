import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'
import Axios, { AxiosResponse } from 'axios'
import { baseUrl } from '../API';

export const UserContext = createContext<Partial<User>>({})

export default function Context(props: PropsWithChildren<any>) {
    const [user, setUser] = useState<User | undefined>(undefined)

    useEffect(() => {
        Axios.get(baseUrl + "/api/current_user", { withCredentials: true }).then((res: AxiosResponse) => {
            setUser(res.data);
        })
    }, []);

    return (
        <UserContext.Provider value={user!}>{props.children}</UserContext.Provider>
    )
}